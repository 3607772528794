import { Injectable } from '@angular/core';
import { Global } from '@iupics-manager/models/global-var';
import { KeyCode } from './keycode.enum';

@Injectable({
  providedIn: 'root'
})
export class KeybindConfigService {
  private static keybinds: any;

  constructor() {}

  load() {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', 'assets/keybinding/keybinding.json');

      xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          const result = JSON.parse(xhr.responseText);
          KeybindConfigService.keybinds = result;
          resolve(KeybindConfigService.keybinds);
        } else if (xhr.readyState === XMLHttpRequest.DONE) {
          reject();
        }
      });

      xhr.send(null);
    });
  }

  getKeyCode(key: string): KeyCode {
    if (key !== undefined && key !== null && key !== '') {
      const value = Global.getValueByPath(KeybindConfigService.keybinds, this.getPath(key));
      return value;
    }
    return KeyCode.NONE;
  }

  private getPath(key: string): string[] {
    while (key.endsWith('.')) {
      key = key.substring(0, key.length - 1);
    }
    return key.split('.');
  }
}
