import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {
  private readonly CHECK_MIN = 60;
  private readonly CHECK_HOUR = 60 * this.CHECK_MIN;
  private readonly CHECK_DAY = 24 * this.CHECK_HOUR;

  transform(value: number | Date, type: 's' | 'ms'): string {
    if (typeof value === 'number') {
      if (type === 'ms') {
        value = Math.floor(value / 1000);
      }
    } else {
      value = Math.floor(value.getTime() / 1000);
    }

    const seconds = value % this.CHECK_MIN;
    const minutes = Math.floor((value / this.CHECK_MIN) % 60);
    const hours = Math.floor((value / this.CHECK_HOUR) % 24);
    const days = Math.floor(value / this.CHECK_DAY);

    const dayStr = days > 0 ? `${days} d. ` : '';
    const hourStr = `${('00' + hours).slice(-2)} h. `;
    const minuteStr = `${('00' + minutes).slice(-2)} min. `;
    const secondStr = `${('00' + seconds).slice(-2)} sec.`;
    return dayStr + hourStr + minuteStr + secondStr;
  }
}
