import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import * as shajs from 'sha.js';

export enum InfoDialogType {
  ERROR = 'error',
  INFO = 'info',
  CONFIRM = 'confirm',
  CONFIRM_YESNO = 'confirmyesno'
}

@Component({
  selector: 'iu-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {
  @ViewChild('confirmYesNo', { static: false }) set confirmYesNoElement(value: ElementRef) {
    if (value) {
      value.nativeElement.focus();
    }
  }
  get confirmYesNoElement(): ElementRef {
    return this.confirmYesNoElement;
  }
  @ViewChild('confirm', { static: false }) set confirmElement(value: ElementRef) {
    if (value) {
      value.nativeElement.focus();
    }
  }
  get confirmElement(): ElementRef {
    return this.confirmElement;
  }
  @ViewChild('confirmOK', { static: false }) set confirmOkElement(value: ElementRef) {
    if (value) {
      value.nativeElement.focus();
    }
  }
  get confirmOkElement(): ElementRef {
    return this.confirmOkElement;
  }
  @Input()
  message: {
    summary: string;
    detail: string;
  };
  @Input()
  dialogType: InfoDialogType;
  @Output()
  confirm = new EventEmitter<any>();
  @Output()
  cancel = new EventEmitter<any>();

  key: string;

  isOpen = false;

  constructor(private messageService: MessageService) {}

  ngOnInit() {
    if (this.message) {
      this.key = shajs('sha256')
        .update(this.message.summary + this.message.detail + this.dialogType + moment().valueOf())
        .digest('hex');
    } else {
      this.key = shajs('sha256')
        .update('global' + moment().valueOf())
        .digest('hex');
    }
  }

  confirmDialog() {
    this.isOpen = false;
    this.confirm.emit();
    this.messageService.clear(this.key);
  }

  cancelDialog() {
    this.isOpen = false;
    this.cancel.emit();
    this.messageService.clear(this.key);
  }

  showInfoDialog() {
    this.isOpen = true;
    this.messageService.add({
      key: this.key,
      sticky: true,
      closable: false,
      severity:
        this.dialogType === InfoDialogType.CONFIRM || this.dialogType === InfoDialogType.CONFIRM_YESNO ? 'warn' : this.dialogType,
      summary: this.message.summary,
      detail: this.message.detail
    });
  }
}
